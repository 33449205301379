import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Layout, media } from "@theme";

export const Container = styled.div``;

export const Wrapper = styled(Layout.Wrapper)`
    width: 100%;
`;

export const TitleWrapper = styled(Layout.Wrapper)``;
export const MainTitleWrapper = styled(Layout.MainTitleWrapper)`
    padding-top: 130px;
    & .hero {
        justify-content: flex-start;
        
        & > div {
            text-align: left;
            padding: 5em 5em 5em 0;

            ${media.tablet} {
                padding: 3em 3em 3em 0;

                h3 {
                    font-size: 20px;
                }
            }
        }
    }
    ${media.small}{
        padding-top: 60px;
    }
`;

export const Section = styled.div`
    min-height: 100px;
    background-color: ${props => props.bgColor ? props.bgColor : "transparent"};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    ${media.tablet} {
        padding: 30px 0;
    }
`;

const mediaStyle = css`
    width: 100%;
    outline: none;
    box-sizing: border-box;
    border-radius: 10px;
`;

export const MediaWrapper = styled(Layout.Wrapper)`
    padding: 60px 0;
    video {
        ${mediaStyle}
    }
`;

export const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const CTAWrapper = styled.div`
    margin-top: 150px;

    ${media.tablet} {
        margin-top: 100px;
    }
`;

