import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import WorkDetail from "../containers/workDetail";
import get from "lodash/get";
import { getSrc } from "gatsby-plugin-image";

const Story = ({ data }) => {
    const node = get(data, 'storiesYaml', []);

    // Get SEO
    const dataSEO = get(node, 'seo', undefined);
    // Get Quotes
    const allQuotes = get(data, 'quotes.nodes', undefined);
    // Get Frames
    const frames = {
        "iphonex": get(data, 'iphonex', undefined),
        "iphone8": get(data, 'iphone8', undefined),
    }

    const imagePath = getSrc(get(dataSEO, 'image.childImageSharp', undefined))

    return (
        <Layout>
            <SEO
                title={(dataSEO && dataSEO.title) || node.title}
                description={(dataSEO && dataSEO.description) || undefined}
                imagePath={imagePath}
            />
            <WorkDetail
                info={node}
                allQuotes={allQuotes}
                frames={frames}
            />
        </Layout>
    )
}

export const query = graphql`

    query($slug: String!) {

        storiesYaml(fields: { slug: { eq: $slug } }) {
            seo {
                title
                description
                image {
                    childImageSharp {
                        gatsbyImageData(width: 1200, height: 620, placeholder: NONE)
                    }
                }
            }
            title
            services
            text
            description
            sections {
                type
                identifier
                width
                order
                image {
                    src {
                        childImageSharp {
                            gatsbyImageData(width: 1440, placeholder: NONE)
                        }
                    }
                    label
                }
                title {
                    label
                    color
                }
                text {
                    label
                    color
                }
                actions {
                    label
                    link
                    bgColor
                    textColor
                }
                backgroundColor
                video {
                    mp4 {
                        publicURL
                        childVideoFfmpeg {
                            webm: transcode(codec: "libvpx-vp9", audioCodec: "libopus" fileExtension: "webm", outputOptions: ["-crf 31", "-b:v 0"]) {
                                width
                                src
                                presentationMaxWidth
                                presentationMaxHeight
                                originalName
                                height
                                aspectRatio
                            }
                            mp4: transcode(codec: "libx264", audioCodec: "aac", fileExtension: "mp4", options:[["-profile:v", "main"], ["-vf", "format=yuv420p"]], outputOptions: ["-movflags faststart"]) {
                                width
                                src
                                presentationMaxWidth
                                presentationMaxHeight
                                originalName
                                height
                                aspectRatio
                            }
                        }
                    }
                    poster {
                        childImageSharp {
                            gatsbyImageData(width: 1440, placeholder: NONE)
                        }
                    }
                }
                videoFrame {
                    src {
                        publicURL
                    }
                    frame
                }
            }
            mobile {
                rating {
                    label
                    stars
                    value
                }
                download {
                    label
                    platforms {
                        type
                        image
                        link
                    }
                }
            }
        }

        quotes:
            allFile(filter: {childQuotesYaml: {id: {ne: null}}}) {
                nodes {
                    id
                    name
                    childQuotesYaml {
                        text
                        author {
                            name
                            ocupation
                            photo {
                                childImageSharp {
                                    gatsbyImageData(width: 44, placeholder: NONE)
                                }
                            }
                        }
                    }
                }
            }

        iphonex:
            file(relativePath: {eq: "images/deviceFrames/frame-iphonex.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 566, placeholder: NONE)
                }
            }

        iphone8:
            file(relativePath: {eq: "images/deviceFrames/frame-iphone8.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 580, placeholder: NONE)
                }
            }
    }
`;

export default Story;
