import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Layout, Colors, media } from "@theme";
import { Paragraph } from "../../UI/Typography";

export const Container = styled.div`
    background: ${props => props.bgColor ? props.bgColor : 'transparent'};
    ${media.small} {
        padding-bottom: 10rem;
    }
`;

// Wrapper
export const Wrapper = styled(Layout.Wrapper)``;

export const FlexContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: flex-start;
    padding: 10rem 5rem;

    ${media.small} {
        flex-direction: column;
        align-items: center;
        padding: 15px;
    }
`;

export const Column = styled.div`
    flex: 0 1 auto;
    align-self: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
    ${media.small} {
        margin: 4rem 0;
    }
`;

export const Item = styled.div``;

export const Value = styled.p`
    font-size: 80px;
    height: 80px;
    line-height: 80px;
    color: ${Colors.rating};
    font-weight: 300;
`;

export const Label = styled(Paragraph)`
    margin-top: 3rem;
    height: 14px;
    line-height: 14px;
    font-size: 1em;
    text-transform: uppercase;
`;

export const Stars = styled.div`
    margin-top: 1rem;
    height: 14px;
    line-height: 14px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
`;

export const StarWrapper = styled.div`
    padding: 3px;
`;

const starStyle = ({ type }) => css`
    height: 13px;
    width: 14px;
    background-image: url(${process.env.METACRAFT_URL || '/'}rating/${type}@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
`;

export const Star = styled.div`
    ${starStyle}
`;