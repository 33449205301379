import styled from "@emotion/styled";

export const Container = styled.div`
    background: ${props => props.bgColor ? props.bgColor : 'transparent'};
`;

// Wrapper
export const Wrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    text-align: center;
`;