import React from "react";
import PropTypes from "prop-types";
import Link from "../UI/Link";
import Image from "../UI/Image";
import {
    Container,
    Wrapper,
    Title,
    Subtitle,
    GridWrapper,
    ElemWrapper,
    ImageWrapper,
    TextWrapper,
    Text,
    ActionsWrapper,
    Button,
} from "./styled";

const Grid = ({ data }) => 
    <GridWrapper>
        {data && data.map((elem, index) =>
            <ElemWrapper key={`cta-grid-elem-${index}`}>
                {elem.image && elem.image.src && <ImageWrapper>
                    <Image image={elem.image.src} alt={elem.image.label} />
                </ImageWrapper>}
                {elem.title &&  elem.title.label && <TextWrapper color={elem.title.color} weight={"regular"}>{elem.title.label}</TextWrapper>}
            </ElemWrapper>
        )}
    </GridWrapper>

// CTA Component
const CTA = ({ bgColor, innerBackground, actions, title, subtitle, text, grid }) => (
    <Container bgColor={bgColor}>
        <Wrapper background={innerBackground}>
            {title && title.label !== "" && <Title weight="bold" color={title.color}>{title.label}</Title>}
            {subtitle && subtitle.label !== "" && <Subtitle weight="medium" color={subtitle.color}>{subtitle.label}</Subtitle>}
            {grid && <Grid data={grid} />}
            {text && <Text weight="bold" color={text.color}>{text.label}</Text>}
            {actions && 
                <ActionsWrapper>
                    {actions.map((action, index) => {
                        const { label, link, ...props} = action;
                        return (
                            <Link key={`cta-link-action-${index}`} to={link} hoverStyle={"none"}>
                                <Button key={`cta-action-${index}`} {...props}>{label}</Button>
                            </Link>
                        )
                    })}
                </ActionsWrapper>}
        </Wrapper>
    </Container>
);

CTA.propTypes = {
    title: PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string,
    }),
    text: PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string,
    }),
    innerBackground: PropTypes.string,
    bgColor: PropTypes.string,
}

CTA.defaultProps = {
    title: {
        label: "",
        color: "#FFFFFF",
    },
    text: {
        label: "",
        color: "#FFFFFF",
    },
    innerBackground: "transparent",
    bgColor: "transparent",
}

export default CTA
