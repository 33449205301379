import React from "react";
import {
    Container,
    Wrapper,
    FlexContainer,
    Column,
    Item,
    Value,
    Label,
    Stars,
    StarWrapper,
    Star,
} from "./styled";

// Rating Component
const Rating = ({ bgColor, data }) =>
    <Container bgColor={bgColor}>
        <Wrapper>
            <FlexContainer>
                {data && data.map((block, index) => <Block key={`rating-block-${index}`} index={index} data={block} />)}
            </FlexContainer>
        </Wrapper>
    </Container>

// Info column
const Block = ({ data, index }) => {
    const { label, stars, value } = data;
    return (
        <Column key={`block-${index}-column`}>
            <Item key={`block-${index}-column-value`}>
                <Value weight="light">{value}</Value>
            </Item>
            <Item key={`block-${index}-column-label`}>
                <Label>{label}</Label>
            </Item>
            {stars !== null && 
                <Item key={`block-${index}-column-rating`}>
                    <Stars>{showStars(stars, index)}</Stars>
                </Item>}
        </Column>
    )
}

// Create stars rating
const showStars = (stars, index) => {
    // Max stars
    const max = 5;

    const allStars = Array(max);
    const full = Math.floor(stars);
    const half = ((stars - full) >= 0.5) ? 1 : 0;

    allStars.fill("full", 0, full);
    allStars.fill("half", full, full + half);
    allStars.fill("empty", full + half, max);

    return allStars.map((type, i) => {
        switch (type) {
            case "full":
                return (
                    <StarWrapper key={`block-${index}-column-rating-full-${i}`}>
                        <Star type="full" />
                    </StarWrapper>
                )
            case "half":
                return (
                    <StarWrapper key={`block-${index}-column-rating-half-${i}`}>
                        <Star type="half" />
                    </StarWrapper>
                )
            default:
                return (
                    <StarWrapper key={`block-${index}-column-rating-empty-${i}`}>
                        <Star type="empty" />
                    </StarWrapper>
                )
        }
    })
}

export default Rating
