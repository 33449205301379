import React from "react";
import Image from "../../components/UI/Image";
import Info from "../../components/Info";
import Quote from "../../components/Quote";
import MobileApp from "../../components/MobileApp";
import Video from "../../components/Video";
import CTA from "../../components/CTA";
import get from "lodash/get";
import {
    Container,
    Wrapper,
    TitleWrapper,
    MainTitleWrapper,
    ImageWrapper,
    MediaWrapper,
    Section,
    CTAWrapper,
} from "./styled";

const canUseWebp = e => typeof window !== 'undefined' && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;

const WorkDetail = ({ info, allQuotes, frames }) =>
    <Container>
        <TitleWrapper>
            <MainTitleWrapper>
                <Info 
                    title={{ label: info.title }}
                    services={info.services}
                    headlineText={{ label: info.text, weight: "regular" }}
                    width={60}
                    mainTitle
                />
            </MainTitleWrapper>
        </TitleWrapper>
        {info.sections && info.sections.map((section, index) => <React.Fragment key={`work-detail-section-${index}`}>{typeSection(section, allQuotes, frames)}</React.Fragment>)}
        {info.mobile && <MobileApp data={info.mobile}/>}
    </Container>

// Render section according to its type
const typeSection = (section, allQuotes, frames) => {
    if(section && section.type) {
        switch (section.type) {
            case 'info':
                const { title, text, backgroundColor, width, order, image, videoFrame } = section;
                let maxWidth = image ? get(image, 'src.childImageSharp.gatsbyImageData.width', undefined) : undefined;
                let infoFrame = undefined;
                if(videoFrame) {
                    infoFrame = frames[videoFrame.frame];
                }

                return (
                    <Section bgColor={backgroundColor}>
                        <Wrapper>
                            {(title || image) && <Info
                                subtitle={title && {
                                    label: title.label,
                                    color: title.color,
                                    weight: "medium",
                                }}
                                text={text && {
                                    label: text.label,
                                    color: text.color,
                                    weight: "regular",
                                }}
                                width={width ? (width/12)*100 : 100}
                                order={order === 'right' ? 1 : 0}
                                image={image ? image : null}
                                imageMaxWidth={maxWidth ? `${maxWidth/3}px` : null}
                                video={videoFrame || undefined}
                                frame={infoFrame || undefined}
                            />}
                        </Wrapper>
                    </Section>
                )
            case 'quote':
                if(section.identifier) {
                    let quotes = allQuotes.filter((quote) => quote.name === section.identifier);
                    if(quotes.length > 0) {
                        let quoteInfo = get(quotes, '[0].childQuotesYaml', []);
                        return (
                            <Section bgColor={section.backgroundColor}>
                                <Wrapper>
                                    <Quote
                                        text={quoteInfo.text}
                                        author={quoteInfo.author}
                                    />
                                </Wrapper>
                            </Section>
                        )
                    }
                }
                return null;
            case 'video':
                let videoFiles = get(section.video, 'mp4.childVideoFfmpeg', undefined);
                let poster = canUseWebp() ? get(section.video, 'poster.childImageSharp.fluid.srcWebp', undefined) : get(section.video, 'poster.childImageSharp.fluid.src', undefined);
                return (
                    <Section bgColor={"#aaa"}>
                        <MediaWrapper>
                            <Video source={videoFiles} poster={poster} />
                        </MediaWrapper>
                    </Section>
                )
            case 'image':
                return (
                    <Section bgColor={section.backgroundColor} style={{padding: 0}}>
                        <Wrapper>
                            <ImageWrapper>
                            {section.image &&
                                <Image
                                    image={section.image.src}
                                    alt={section.image.label}
                                />}
                            </ImageWrapper>
                        </Wrapper>
                    </Section>
                )
            case 'cta':
                return (
                    <CTAWrapper>
                        <CTA
                            title={section.title && {
                                label: section.title.label,
                                color: section.title.textColor,
                            }}
                            text={section.text && {
                                label: section.text.label,
                                color: section.text.textColor,
                            }}
                            actions={section.actions}
                        />
                    </CTAWrapper>
                )
            default:
                return <Section bgColor={section.backgroundColor} />
        }
    }
    return null;
}

export default WorkDetail
