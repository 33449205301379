import React from "react";

export default ({ source, poster }) => {
    
    return (
        <video controls poster={poster ? poster : ''} style={{ width: `100%` }}>
            {source && source.webm && source.webm.src && <source src={source.webm.src} type="video/webm" />}
            {source && source.mp4 && source.mp4.src && <source src={source.mp4.src} type="video/mp4" />}
        </video>
    )
}