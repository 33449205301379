import styled from "@emotion/styled";
import { Body1 } from "../../UI/Typography";
import Image from "../../UI/Image";

export const Container = styled.div`
    background: ${props => props.bgColor ? props.bgColor : '#eee'};
`;

// Wrapper
export const Wrapper = styled.div`
    padding: 7rem 0;
`;

export const PlatformsWrapper = styled.div`
    margin-top: 4rem;
    padding: 4rem;
`;

export const Title = styled(Body1)``;
export const PlatformImage = styled(Image)`
    width: 203px;
    margin: 0 auto;
    padding: 15px;
`;