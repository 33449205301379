import React from "react";
import Link from "../../UI/Link";
import {
    Container,
    Wrapper,
    Title,
    PlatformsWrapper,
    PlatformImage,
} from "./styled";

// Download Component
const Download = ({ data }) => {
    const { label, platforms } = data;
    return (
        <Container>
            <Wrapper>
                <Title>{label}</Title>
                <PlatformsWrapper>
                    {platforms && platforms.map((item, index) => {
                        const { type, image, fullImage, link } = item;
                        return (
                            <Link to={link} hoverStyle="none" key={`download-${index}`}>
                                <PlatformImage image={fullImage || `/download/${image}`} alt={type} />
                            </Link>
                        )
                    })}
                </PlatformsWrapper>
            </Wrapper>
        </Container>
    );
}

export default Download
