import React from "react";
import Download from "./Download";
import Rating from "./Rating";
import get from "lodash/get";
import {
    Container,
    Wrapper,
} from "./styled";

// Mobile Component
const MobileApp = ({ data }) => {
    // Get Ratings
    const ratings = get(data, 'rating', undefined);
    // Get Download
    const download = get(data, 'download', undefined);

    return (
        <Container>
            <Wrapper>
                {ratings && <Rating data={ratings} />}
                {download && <Download data={download} />}
            </Wrapper>
        </Container>
    )
}

export default MobileApp
